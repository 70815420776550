<template>
  <section class="home container-fluid p-0" id="home">
    <Header class="header-desk" />
    <HeaderMobile class="header-mob" />

    <div class="home-txt">
      <div class="bg-cnt">
        <svg
          width="1367"
          height="1125"
          viewBox="0 0 1367 1125"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_104_37)">
            <path
              d="M131.426 691.111C115.5 691.174 100.253 696.752 89.0329 706.622C77.8124 716.492 71.5347 729.847 71.5778 743.756L72.3981 995.459C72.4029 1011.25 79.5911 1026.4 92.3816 1037.56C105.172 1048.73 122.517 1055 140.602 1055H652.936C675.647 1055 697.434 1047.15 713.53 1033.15C729.625 1019.16 738.718 1000.17 738.818 980.336L738.926 959.316V869.056C738.926 859.555 741.073 850.147 745.244 841.371C749.415 832.596 755.527 824.626 763.232 817.917C770.936 811.208 780.081 805.893 790.141 802.277C800.202 798.66 810.981 796.813 821.86 796.841L1224.28 797.951C1233.82 797.978 1243.27 796.36 1252.09 793.191C1260.91 790.022 1268.93 785.363 1275.69 779.482C1282.44 773.601 1287.8 766.613 1291.46 758.92C1295.12 751.226 1297 742.977 1297 734.647V323.089C1297 312.293 1294.56 301.602 1289.82 291.629C1285.09 281.655 1278.15 272.594 1269.4 264.962C1260.66 257.331 1250.28 251.278 1238.85 247.151C1227.43 243.024 1215.19 240.902 1202.82 240.907C1142.8 240.907 1066.18 241.324 1010.81 241.694C1001.27 241.751 991.798 240.158 982.958 237.006C974.117 233.855 966.077 229.206 959.302 223.33C952.527 217.453 947.15 210.464 943.481 202.765C939.811 195.066 937.923 186.809 937.923 178.469V124.014C937.924 116.921 936.325 109.898 933.218 103.344C930.111 96.7912 925.556 90.8366 919.813 85.8209C914.071 80.8051 907.253 76.8263 899.75 74.1117C892.247 71.3972 884.205 70 876.083 70H121.32C107.975 69.9999 95.1739 74.6206 85.723 82.8492C76.2721 91.0778 70.9423 102.243 70.9017 113.898L70.0002 430.441C69.9765 437.925 71.6442 445.34 74.9077 452.261C78.1712 459.181 82.9664 465.472 89.0184 470.771C95.0705 476.07 102.26 480.274 110.176 483.143C118.092 486.011 126.578 487.487 135.149 487.486H402.25C410.779 487.489 419.222 488.963 427.097 491.823C434.972 494.683 442.122 498.873 448.137 504.153C454.153 509.433 458.915 515.698 462.151 522.59C465.386 529.481 467.032 536.862 466.993 544.31L466.596 633.743C466.518 648.624 459.729 662.879 447.7 673.418C435.671 683.958 419.37 689.933 402.331 690.048L131.426 691.111Z"
              fill="#22D4DF"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_104_37"
              x="0"
              y="0"
              width="1367"
              height="1125"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="35"
                result="effect1_foregroundBlur_104_37"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <h1>WHERE ARE WE NOW?</h1>
      <p>
        NFTBERLIN UNCONFERENCE <br />
        COMING IN MAY 2024
      </p>

      <!--  <div class="sub-txt-cnt">
        <p>
          art collectibles & society, game & experiences financialization,
          infrastructure & research, nft art exhibition
        </p>
      </div> -->

      <div class="btn-cnt d-flex">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdSRWxHXJy8ggdo_-6bCm8FJ8jKX7FDDMf0jyDhBaUyeKxifQ/viewform?usp=sharing"
          target="_blank"
        >
          <button class="btn me-4">PRESS APPLICATION</button>
        </a>
        <a
          href="https://forms.gle/trjuXhNGYNwM4P6C9"
          target="_blank"
        >
          <button class="btn ms-4">ARTIST APPLICATION</button>
        </a>
      </div>
      <div class="venue">HOLZMARKT 25, BERLIN</div>

      <div class="copy">
        DEVELOPED WITH LOVE BY
        <a href="https://yomi.digital" target="_blank">YOMI</a>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeaderMobile from "@/components/HeaderMobile.vue";

export default {
  name: "Home",
  components: {
    Header,
    HeaderMobile,
  },
};
</script>
